import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from '../blogs.module.scss';
import BlogImg from '../../../../assets/IMG/blog/04-03-2022.png';
import ReCAPTCHA from "react-google-recaptcha";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_AddToNewsLetter, API_BaseURL } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Props {
  location: string;
}

const EmploymentLaw04032022: React.FC<Props> = ({ location }) => {
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Blog/Employment-Law-04-03-2022` : "";
  const [email, setEmail] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const Join = async () => {
    setLoading(true);

    if (captcha && email) {
      await Fetch(API_AddToNewsLetter, email).then((Success: boolean) => {
        if (Success) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            setEmail("");
          }, 3000);
        }
      })
    }

    setLoading(false);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Blog :: Employment Law 04-03-2022</title><meta charSet='utf-8' />
        <meta property="og:description" content="The Mirror reported a concierge was sacked after he couldn't understand how to use Microsoft Teams." />
        <meta property="og:image" content={BlogImg} />
        <meta property="og:title" content="PrimeHR :: Blog :: Employment Law 04-03-2022" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content={shareURL} />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Blog", Text: "Blog" },
        { To: "", Text: "Employment Law 04-03-2022" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>Employment Law - Policy, Case Law & Updates</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <span>Published On {new Date(2022, 1, 4).toDateString()}</span>
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={BlogImg} />
          </div>

          <h3>Sacked for not turning up for a Disciplinary on Teams</h3>

          <p>
            The Mirror reported a concierge was sacked after he couldn't understand how to use Microsoft Teams.
          </p>
          <p>
            It continues:
          </p>
          <p>
            "The Claimant was called to a disciplinary hearing as the pandemic hit and was invited to attend a Microsoft Teams hearing on March 31, 2020.
          </p>
          <p>
            "He expressed his anxiety was so bad he even had thoughts of taking his own life."
          </p>
          <p>
            The Tribunal held:
          </p>
          <p>
            "The Respondent prepared a Microsoft Teams invitation whereby a hyperlink was sent by email."
          </p>
          <p>
            "This email did not give any instructions as to how to join. Microsoft Teams could be accessed via the internet by clicking the link."
          </p>
          <p>
            "We accept the Claimant did not know what Microsoft Teams was, he did not have a camera on his desktop computer and could not connect."
          </p>
          <p>
            "This was week two of the first pandemic lockdown. Many people did not know at that stage about Microsoft Teams and had not used it."
          </p>
          <p>
            "Another Teams link was sent to him. [He was warned] if he did not attend without reasonable cause or explanation then a decision may be taken in his absence."
          </p>
          <p>
            "We have concluded that there was a substantial difference between his ability to respond to the connection difficulties and a non-disabled person's."
          </p>
          <p>
            "It was a substantial difference because his extreme anxiety was very much part of the reason why he did not try to find an alternative way to attend the hearing on the day."
          </p>
          <p>
            "A non-disabled person would not have had this additional mental obstacle."
          </p>
          <p>
            "The Tribunal held that the Respondent 'ought to have known' his anxiety was a factor in him not contacting them about Teams and concluded they were unreasonable in proceeding in his absence and not rescheduling a third hearing by phone."
          </p>
          <p>
            Read the judgement <a target="_blank" href='https://assets.publishing.service.gov.uk/media/620ccc5a8fa8f54916f45e40/Mr_D_Hayes_-v-_Rendall___Rittner_Limited_-_3201451_2020_-_Reasons.pdf'>here</a>.
          </p>

          <h3>In The NEWS! Care workers could get jobs back after being dismissed</h3>

          <p>
            After the government u-turn on compulsory vaccinations for Care workers repeals its mandate on Covid-19 jabs for the sector, don't be surprised if your ex-member of staff asks for their job back!
          </p>
          <p>
            What if you've replaced them or don't want them back?
          </p>
          <p>
            Our Director of Legal Services Nikki, says "its been a difficult time for the sector, which has already seen a skills shortage for a number of years. The question really is how many former employees will want to come back?"
          </p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.SectionSideInfo}>
            <h4>Keep Up To Date</h4>
            <p>If you find our blog updates useful then consider subscribing to our newsletter for regular updates on UK Employment Law</p>

            <label>
              <span>Your Email:</span>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email..." />
            </label>

            <div className="capcha">
              <ReCAPTCHA
                onExpired={() => { setCaptcha("") }}
                onErrored={() => { setCaptcha("") }}
                sitekey="6Lf-XiQaAAAAAI5mHpzBUc6vtyvEWTS17PLcGnWT" onChange={e => { setCaptcha(e); }} />
            </div>

            <div className={styles.Download}>
              <button className={success ? styles.Green : ""} disabled={!captcha || !email} type="button" onClick={() => Join()}>{loading ? <><i><FontAwesomeIcon icon={faSpinner} spin={true} /></i> Joining...</> : success ? <><i><FontAwesomeIcon icon={faCheckDouble} /></i> Successfully Joined!</> : "Join Newsletter"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmploymentLaw04032022;