import React from 'react';
import Layout from '../../components/Layout/layout';
import EmploymentLaw04032022 from '../../Views/Website/Blog/EmploymentLaw04032022/EmploymentLaw04032022';

const EmploymentLaw04032022Page: React.FC = (props: any) => {
  return (
    <Layout>
      <EmploymentLaw04032022 location={props.location.href} />
    </Layout>
  );
};

export default EmploymentLaw04032022Page;
